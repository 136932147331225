import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown/with-html"
import Img from "gatsby-image/withIEPolyfill"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { LandingWrapper, LandingText, LandingImgWrapper } from "../styles"

const Landing = ({ data }) => {
  const home = data.strapiHome

  return (
    <Layout>
      <SEO title="Home" />
      <LandingWrapper row justifyBetween alignCenter>
        <LandingImgWrapper>
          <Img
            style={{ borderRadius: "5px" }}
            fluid={home.picture.childImageSharp.fluid}
          />
        </LandingImgWrapper>
        <LandingText>
          <ReactMarkdown source={home.intro} escapeHtml={false} />
        </LandingText>
      </LandingWrapper>
    </Layout>
  )
}

export default Landing

export const homeQuery = graphql`
  query {
    strapiHome {
      intro
      picture {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`
